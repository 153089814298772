import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import {
    Avatar,
    Box,
    Button,
    ExpandingMenu,
    Hide,
    Stack,
    Text,
    Badge,
    ButtonLink,
} from 'pws-design-system/design-system';
import DashboardLayout from '../layouts/DashboardLayout';
import ContentPanelLayout from '../layouts/ContentPanelLayout';
import ProtectedResource from '../ProtectedResource';
import useApi, { Api } from '../hooks/api/useApi';

interface DownloadLinkProps {
    href: string,
    text: string
}

const DownloadLink = (props: DownloadLinkProps) => {
    const { href, text } = props;
    const linkRef = useRef(null);

    useLayoutEffect(() => {
        linkRef.current.click();
    });

    return (
        <>
            <ButtonLink href={href}>
                <span ref={linkRef}>{text}</span>
            </ButtonLink>
        </>
    );
};

interface DownloadPageProps {
    ['*']: string
}

export const Download = (props: DownloadPageProps & any) => {
    const token = props['*'];
    const linkRef = useRef(null);

    const { request: getDownload, result, isLoading } = useApi<Stations>((api: Api, params: any) => api.routes.download.getDownload(params));

    useEffect(() => {
        getDownload(token);
    }, []);

    return (
        <ProtectedResource>
            <DashboardLayout title="Download" {...props}>
                <ContentPanelLayout title="Download">
                    <Box>

                        <Text variant="subheadline" mb={4}>
                            Your download should have begun. If not, please click the button
                            below.
                        </Text>
                        {!isLoading && result.response && (
                            <DownloadLink href={result.response.data.response.url} text="Download File" />
                        )}
                    </Box>
                </ContentPanelLayout>
            </DashboardLayout>
        </ProtectedResource>
    );
};

export default Download;
